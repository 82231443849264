// alpine
import Alpine from 'alpinejs';
import ui from '@alpinejs/ui';
import focus from '@alpinejs/focus';

import AOS from "aos";

Alpine.plugin(ui);
Alpine.plugin(focus);
window.Alpine = Alpine;
Alpine.start();

window.addEventListener("load", (event) => {
    AOS.init({
        once: "true",
    });
});
